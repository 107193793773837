import React, { useState } from 'react';

function MathExercisesGenerator() {
    const [numOfExercises, setNumOfExercises] = useState(20);
    const [minNum, setMinNum] = useState(99);
    const [maxNum, setMaxNum] = useState(9999);
    const [numDigits, setNumDigits] = useState(0);
    const [sign, setSign] = useState("+");
    const [exercises, setExercises] = useState([]);
    const [corrections, setCorrections] = useState([]);

    function handleGenerateExercises() {
        let newExercises = [];
        let newCorrections = [];
        for (let i = 0; i < numOfExercises; i++) {
            let num1 = Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
            let num2 = Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
            if (numDigits > 0) {
                num1 = Math.floor(Math.random() * (9 * (10 ** (numDigits - 1)))) + (10 ** (numDigits - 1));
                num2 = Math.floor(Math.random() * (9 * (10 ** (numDigits - 1)))) + (10 ** (numDigits - 1));
            }

            let operation = Math.max(num1, num2) + sign + Math.min(num1, num2);
            let result = eval(operation);

            newExercises.push(<span className='opContainer'><span className='number'>{Math.max(num1, num2)}</span> <span className='opSign plus'>{sign}</span> <span className='number'>{Math.min(num1, num2)}</span><span className='line'></span> <span className='opSign equal'>=</span> </span>);
            newCorrections.push(<span className='opContainer'><span className='number'>{Math.max(num1, num2)}</span> <span className='opSign plus'>{sign}</span> <span className='number'>{Math.min(num1, num2)}</span><span className='line'></span> <span className='opSign equal'>=</span> <span className='correct number'>{result}</span></span>);
        }
        setExercises(newExercises);
        setCorrections(newCorrections);
    }

    return (
        <div className='container'>
            <div className='formGenerator'>
                <h2>Math Exercises Generator</h2>
                <form>
                    <label>
                        Number of exercises:
                        <input type="number" value={numOfExercises} onChange={(e) => setNumOfExercises(parseInt(e.target.value))} />
                    </label>
                    <br />
                    <label>
                        Minimum number:
                        <input type="number" value={minNum} onChange={(e) => setMinNum(parseInt(e.target.value))} />
                    </label>
                    <br />
                    <label>
                        Maximum number:
                        <input type="number" value={maxNum} onChange={(e) => setMaxNum(parseInt(e.target.value))} />
                    </label>
                    <br />
                    <label>
                        Number of digits (0 for any):
                        <input type="number" value={numDigits} onChange={(e) => setNumDigits(parseInt(e.target.value))} />
                    </label>
                    <br />
                    <label>
                        Sign:
                        <select value={sign} onChange={(e) => setSign(e.target.value)}>
                            <option value={"+"}>+</option>
                            <option value={"-"}>-</option>
                            <option value={"*"}>x</option>
                        </select>
                    </label>
                    <br />
                    <button type="button" onClick={handleGenerateExercises}>Generate</button>
                </form>

            </div>
            {
                exercises.length > 0 &&
                <ul>
                    {exercises.map((exercise, index) => <li key={index}>{exercise}</li>)}
                </ul>
            }
            {
                corrections.length > 0 &&
                <ul>
                    {corrections.map((exercise, index) => <li key={index}>{exercise}</li>)}
                </ul>
            }
        </div>
    );
}

export default MathExercisesGenerator;